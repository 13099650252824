<template>
  <div>
    <v-img class="imgheight" src="@/assets/banner-bg.jpg">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text align="right" justify="right">
          <v-card
            class="bgclr"
            color="white"
            elevation="5"
            max-width="420"
            height="fit-content"
          >
            <v-row justify="center" class="mt-5">
              <v-img
                max-height="150"
                max-width="150"
                src="@/assets/PrezencePrint.png"
              ></v-img>
            </v-row>
            <v-card-title class="justify-center"
              ><h2>Forgot Password?</h2></v-card-title
            >
            <v-card-title class="titlecss">
              <v-spacer />
            </v-card-title>
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card-text class="mt-0">
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent
                  >
                    <v-text-field
                      rounded
                      outlined
                      class=""
                      append-icon="mdi-person"
                      label="Enter E-Mail ID"
                      color="primary"
                      background-color="white"
                      :rules="idrules"
                      value
                      dense
                      v-model="username"
                    ></v-text-field>
                    <v-row justify="center">
                      <span class="white--text"
                        ><h1>
                          This is the Username you use to login to your account
                        </h1></span
                      >
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="2">
                <v-card-text>
                  <v-form
                    ref="form1"
                    v-model="valid1"
                    lazy-validation
                    @submit.prevent
                  >
                    <v-text-field
                      append-icon="mdi-key"
                      label="Code "
                      type="string"
                      outlined
                      color="primary"
                      background-color="white"
                      dense
                      class="mr-6 ml-6"
                      rounded
                      v-model="code"
                      :rules="codeValidationRules"
                    ></v-text-field>
                    <v-row
                      ><v-spacer />
                      <v-btn
                        class="mt-n3"
                        color="primary"
                        @click="callmethod"
                        small
                        text
                        >resend code?</v-btn
                      ></v-row
                    >
                    <v-row justify="center">
                      <span
                        ><h1>
                          The code is sent to the email-
                          {{ this.username1 }}
                        </h1></span
                      ></v-row
                    >
                  </v-form>
                </v-card-text>
              </v-window-item>

              <v-window-item :value="3">
                <v-card-text>
                  <v-form ref="form2" v-model="valid2">
                    <v-text-field
                      :append-icon="
                        show1 ? 'mdi-lock-open-outline' : 'mdi-lock-outline'
                      "
                      :type="show1 ? 'text' : 'password'"
                      label=" New Password"
                      @click:append="show1 = !show1"
                      outlined
                      class="mr-6 ml-6"
                      color="primary"
                      background-color="white"
                      dense
                      rounded
                      v-model="newPassword"
                      :rules="rules.newPassword"
                    ></v-text-field>
                    <v-text-field
                      :append-icon="
                        show2 ? 'mdi-lock-open-outline' : 'mdi-lock-outline'
                      "
                      label="Confirm Password"
                      :type="show2 ? 'text' : 'password'"
                      outlined
                      @click:append="show2 = !show2"
                      class="mr-6 ml-6"
                      color="primary"
                      background-color="white"
                      dense
                      rounded
                      v-model="confirmPassword"
                      :rules="rules.confirmPassword"
                    ></v-text-field>
                    <v-row justify="center">
                      <span
                        ><h1>
                          Please enter a new Password for your account.
                        </h1></span
                      >
                    </v-row>
                    <v-row class="ml-8 mr-8 mt-3">
                      <v-btn
                        block
                        class="gradient-bg white--text"
                        :loading="loading"
                        @click="resetPassword"
                        :disabled="!valid"
                        >Reset</v-btn
                      >
                    </v-row>
                    <v-card-text align="left" justify="start">
                      <a @click="onBackClick" style="margin-left: 45%">
                        <u><h1 class="ml-8">Back</h1></u>
                      </a>
                    </v-card-text>
                  </v-form>
                </v-card-text>
              </v-window-item>
            </v-window>

            <!-- <v-divider></v-divider> -->
            <v-card-actions class="mx-6">
              <a @click="backToSignin" v-if="step == 1">
                <u><h1>Back to Login</h1></u>
              </a>

              <a class="ml-3" @click="onBackClick" v-if="step == 2">
                <u><h1>Back</h1></u>
              </a>

              <v-spacer></v-spacer>

              <v-btn
                v-if="step == 1"
                :v-show="false"
                rounded
                class="gradient-bg white--text"
                depressed
                @click.prevent="onClickNext"
                :disabled="!valid"
                >Next</v-btn
              >
              <v-card-actions> </v-card-actions>
              <v-btn
                v-if="step == 2"
                :v-show="false"
                rounded
                class="gradient-bg mr-3 white--text"
                depressed
                @click.prevent="onClickStep2"
                >Next</v-btn
              >
            </v-card-actions>
            <v-card-actions> </v-card-actions>
          </v-card>
        </v-card-text>
      </v-form>
    </v-img>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { rules } from "@/mixins/rules.js";
export default {
  name: "ForgotPassword",
  mixins: [rules],
  data() {
    return {
      username: "",
      username1: "",

      show1: false,
      show2: false,
      valid: true,
      valid1: true,
      valid2: true,
      step: 1,
      loading: false,
      code: "",
      idrules: [
        (v) => !!v || "Id is required",
        (v) => !/\s/.test(v) || "Id must not contain Spaces",
        (v) => !/-/.test(v) || "Id must not contain -",
      ],
      codeValidationRules: [(v) => !!v || "Code is required"],
      newPassword: "",
      confirmPassword: "",
    };
  },
  methods: {
    callstr() {
      this.str = this.username;
      this.str1 = this.str.split("@");
      this.str2 = this.str1[0];
      this.str3 = this.str2.slice(0, 1);
      this.str4 = this.str2.slice(this.str2.length - 1);
      this.str5 = this.str3 + "***" + this.str4 + "@";
      this.str6 = this.str1[1];
      this.str7 = this.str6.split(".");
      this.str8 = this.str7[0];
      this.str9 = this.str7[1];
      this.str10 = this.str8.slice(0, 1);
      this.str11 = this.str8.slice(this.str8.length - 1);
      this.str12 = this.str10 + "***" + this.str11 + "." + this.str9;
      this.username1 = this.str5 + this.str12;
    },
    callmethod() {
      Auth.forgotPassword(this.username).then(() => {
      });
      this.$store.commit("snackbar/showMessage", {
        content: "Code re-sent successfully. Enter the new code to proceed",
        color: "green",
      });
    },
    onClickNext: function () {
      if (this.$refs.form.validate()) {
        Auth.forgotPassword(this.username)
          .then(() => {
            this.callstr();
            this.step++;
          })
          .catch((err) => {
            this.errormessage(err.message);
          });
      }
    },
    resetPassword: function () {
      if (this.$refs.form2.validate()) {
        Auth.forgotPasswordSubmit(this.username, this.code, this.newPassword)
          .then(() => {
            this.$router.push("/");
            this.$store.commit("snackbar/showMessage", {
              content: "Password changed successfully. Login to Proceed!",
              color: "green",
            });
          })
          .catch((err) => {
            if (err.code === "CodeMismatchException") this.step--;
            console.error(err);
            this.errormessage(err.message);
          });
      }
    },
    errormessage(message = "Something has gone wrong, please try again. ") {
      this.$store.commit("snackbar/showMessage", {
        content: message,
        color: "red",
      });
    },
    onClickStep2() {
      if (this.$refs.form1.validate()) {
        this.step++;
      }
    },
    onBackClick() {
      this.step--;
    },
    backToSignin() {
      this.$router.push("/");
    },
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Enter your Username";
        case 2:
          return "Enter the Code";
        default:
          return "Create a New password";
      }
    },
  },
};
</script>
<style >
.textcolor-grey {
  color: grey !important;
  margin-left: 10px !important;
}
.textcolor-black {
  color: white !important;
  margin-left: 10px !important;
}
.resetcss {
  margin-top: 2px !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.login-css {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
