<template>
  <v-app>
    <router-view />
    <Snackbar />
  </v-app>
</template>

<script>
import Snackbar from "@/components/snackbarStore.vue";
export default {
  components: {
    Snackbar,
  },
};
</script>

<style>
.gradient-btn {
  background: -webkit-linear-gradient(#f4813f 40%, #f1556a 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}
/* .bx--text-truncate--front {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  position: relative;
}*/
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
} */
.gradient-bg2 {
  /* .focus-login-input:before,

.blog-pagination .page-link:hover,

.blog-pagination .page-item.active .page-link,

.blog_right_sidebar .tag_cloud_widget ul li a:hover,

.left_question_inner .card .card-heading h4 a i:before,

.testimonials_area .owl-controls .owl-dots .owl-dot:hover,

.testimonials_area .owl-controls .owl-dots .owl-dot.active { */

  background: -webkit-linear-gradient(
    145deg,
    #f4813f 20%,
    #f1556a 60%,
    #f4813f 20%
  );

  background: -moz-linear-gradient(
    145deg,
    #f4813f 20%,
    #f1556a 60%,
    #f4813f 20%
  );

  background: -o-linear-gradient(145deg, #f4813f 20%, #f1556a 60%, #f4813f 20%);

  background: -ms-linear-gradient(
    145deg,
    #f4813f 20%,
    #f1556a 60%,
    #f4813f 20%
  );
  background: linear-gradient(145deg, #f4813f 20%, #f1556a 60%, #f4813f 20%);
}
.rowclick {
  cursor: pointer;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: left !important ;
}
.headcss {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: left !important;
  text-align-last: left;
}
.headcolor {
  color: #324755 !important ;
}
.clrqr {
  color: #324755;
}
.gradient-btn {
  background: -webkit-linear-gradient(#f4813f 40%, #f1556a 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
h3 {
  font-size: 25px;
  background: -webkit-linear-gradient(#f4813f 30%, #f1556a 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
h2 {
  font-size: 23px;
  background: -webkit-linear-gradient(#f4813f 30%, #f1556a 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
h1 {
  font-size: 15px;
  background: -webkit-linear-gradient(#f4813f 30%, #f1556a 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.largeFontSize{
  font-size: 25px !important;
}
.FontSize {
  font-size: 8px !important;
}
</style>
