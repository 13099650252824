<template>
  <div>
    <v-app
      :style="{
        'background-image': 'url(' + require('@/assets/banner-bg.jpg') + ')',
      }"
      style="background-size: cover"
    >
      <v-container class="fill-height" fluid>
        <v-row>
          <v-col cols="6"></v-col>
          <v-col cols="6">
            <v-card class="mx-auto rounded-xl" height="410" width="400">
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-center">
                    <v-img
                      max-width="80"
                      max-height="100"
                      class="mt-5"
                      src="@/assets/PrezencePrint.png"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="green"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                  <v-window v-model="steps" touchless>
                    <v-window-item :value="1">
                      <v-card-text>
                        <h3 class="text-center font-weight mb-4">
                          Welcome Back!
                        </h3>
                        <!-- <h4 class="text-center font-weight mb-2">
                        We're so excited to see you again!
                      </h4> -->
                        <v-form>
                          <div v-if="otpVisible == 0">
                            <v-text-field
                              dense
                              outlined
                              label="Email"
                              v-model="userEmail"
                              :rules="[rules.required, rules.email]"
                              required
                              append-icon="mdi-email"
                              type="text"
                              color="primary"
                              @keyup.enter="validate_sign_in('otp')"
                              style="font-size: 13px"
                            >
                            </v-text-field>
                          </div>
                          <div v-if="otpVisible == 1">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              label="Email"
                              v-model="userEmail"
                              :rules="[rules.required, rules.email]"
                              required
                              append-icon="mdi-email"
                              type="text"
                              color="primary"
                              style="font-size: 13px"
                            >
                            </v-text-field>
                          </div>
                          <div v-show="otpVisible == 1">
                            <v-row no-gutters>
                              <v-otp-input
                                v-model="otpInput"
                                :ref="otpRef"
                                :key="otpverificarionNumber"
                                length="6"
                              ></v-otp-input>
                              <div class="ml-2 font-weight-medium">
                                Resend OTP in ({{ timer }}s)
                                <v-icon
                                  v-if="!timerRunning"
                                  color="#EF6C00"
                                  class="mb-2 ml-2 mt-1"
                                  @click="restartTimer('otp')"
                                  >mdi-refresh</v-icon
                                >
                              </div>

                              <!-- <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="validate_sign_in('otp')"
                                >
                                  mdi-refresh-circle
                                </v-icon>
                              </template>
                              <span>Resend OTP</span>
                            </v-tooltip> -->
                            </v-row>
                          </div>
                          <div v-if="otpVisible == 0">
                            <v-btn
                              block
                              dark
                              class="mt-4 elevation-0 text-capitalize gradient-bg white--text"
                              :loading="loading1"
                              @click="validate_sign_in('otp')"
                              >Send OTP</v-btn
                            >
                            <!-- <v-row class="mt-2" no-gutters>
                            <div>
                              Need an account ?
                              <v-btn
                                x-small
                                text
                                color="#ef3f6e"
                                @click="sign_up_action()"
                                ><b><u>Register</u></b></v-btn
                              >
                            </div>
                          </v-row> -->
                          </div>
                          <div v-if="otpVisible == 1">
                            <v-btn
                              block
                              dark
                              class="mt-4 text-capitalize gradient-bg white--text"
                              :loading="loading1"
                              @click="validate_sign_in('login')"
                              >Login</v-btn
                            >
                          </div>
                        </v-form>
                      </v-card-text>
                    </v-window-item>
                  </v-window>
                  <!-- </v-col> -->
                  <!-- </v-card> -->
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>

    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>
  
  <script>
/* eslint-disable */
import SnackBar from "@/components/SnackBar.vue";

import { Auth } from "aws-amplify";
export default {
  components: {
    SnackBar,
  },

  data() {
    return {
      userEmail: "",
      SnackBarComponent: {},
      Getorgcurrent: {},
      GetCurrentUserObject2: {},
      GetCurrentOrgObject: {},
      steps: null,
      loading1: false,
      otpInput: "",
      otpverificarionNumber: false,
      otpVisible: 0,
      userDetails: {},
      buttonload: false,
      isEmailEntered: false,
      timerRunning: false,
      timer: 120,
      otpRef: "otpField",
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid Email";
        },
      },
      Reactivatedailog: false,
    };
  },
  created() {},
  methods: {
    startTimer() {
      this.timerRunning = true;
      this.timer = 120;
      this.countdown();
    },
    restartTimer() {
      this.validate_sign_in("otp");
      this.timer = 120;
      this.countdown2();

      this.timerRunning = true;
    },
    countdown() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer--;
          this.countdown();
        }, 1000);
      } else {
        this.timerRunning = false;
      }
    },
    countdown2() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer--;
          this.countdown2();
        }, 3000);
      } else {
        this.timerRunning = false;
      }
    },

    validate_sign_in(val) {
      var valid = true;

      if (this.userEmail == "") {
        valid = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Email  Is Required",
          timeout: 5000,
          Top: true,
        };
      }
      if (valid) {
        if (val == "otp") {
          this.startTimer();
          this.generate_otp();
        } else {
          this.login_with_otp();
        }
      }
    },
    focusOnOTPField() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[this.otpRef].focus();
        }, 100); // Adjust the delay as needed (100ms in this case)
      });
    },
    async generate_otp() {
      this.loading1 = true;
      try {
        const user = await Auth.signIn(this.userEmail)
          .then((user) => {
            this.userDetails = user;

            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "OTP Sent Successfully",
              timeout: 5000,
              Top: true,
            };
            this.focusOnOTPField();
            this.otpVisible = 1;

            this.loading1 = false;
          })
          .catch((err) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Couldn't find your Prezence account!!",
              timeout: 5000,
              Top: true,
            };
            this.loading1 = false;
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.message,
          timeout: 5000,
          Top: true,
        };
        this.loading1 = false;
      }
    },
    sign_up_action() {
      this.$router.push("/Signup");
    },

    async login_with_otp() {
      this.loading1 = true;
      console.log(this.userDetails, this.otpInput);
      try {
        await Auth.sendCustomChallengeAnswer(this.userDetails, this.otpInput);

        const user = await Auth.currentAuthenticatedUser();
        this.$store.commit("SET_AUTH", true);
        this.$store.commit("SET_USEREMAIL", this.userEmail);
        // console.log(user);
        this.$store.commit("SET_CURRENT_ROUTE", 0);
        this.$router.push("/Dashboard");
      } catch (error) {
        // this.otpInput = "";

        this.$forceUpdate();

        if (error.message == "Incorrect username or password.") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Invalid OTP",
            timeout: 5000,
            Top: true,
          };
          this.otpInput = "";
        } else if (error == "The user is not authenticated") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Invalid OTP",
            timeout: 5000,
            Top: true,
          };
        } else if (error.message == "Challenge response cannot be empty") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Please Enter OTP Manually",
            timeout: 5000,
            Top: true,
          };
        } else if (error.message == "Invalid session for the user.") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText:
              "Multiple Attempts, Please Resend OTP and Login again",
            timeout: 5000,
            Top: true,
          };
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: error.message,
            timeout: 5000,
            Top: true,
          };
        }
        this.loading1 = false;
      }
    },
  },
};
</script>
  
  <style>
.gradient-bg {
  background: -webkit-linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
  background: -moz-linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
  background: -o-linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
  background: -ms-linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
  background: linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
}
</style>