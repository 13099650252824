export const rules = {
  data() {
    return {
      rules: {
        id: [
          (v) => !!v || "Id is required",
          (v) => !/\s/.test(v) || "Id must not contain Spaces",
          (v) => !/-/.test(v) || "Id must not contain -",
        ],
        name: [
          (v) => !!v || "Name is required",
        ],
        pub: [
          (v) => !!v || "Publisher is required",
        ],
        newPassword: [
          (v) => !!v || "Password is required",
          (v) =>
            (v && v.length > 8) ||
            "Password cannot be less than 8 characters",
          (v) => /[A-Z]/.test(v) || "Password must contain a capital letter",
          (v) => /[0-9]/.test(v) || "Password must contain a number",
          (v) =>
            /[|\\/~^:,;?!&%$@*+#]/.test(v) || "Password must contain a symbol",
        ],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) =>
            (v && this.newPassword == v) ||
            "Password not matching",
        ]
      },
    }
  }
}