import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import createPersistedState from "vuex-persistedstate";

Vue.use(VueAxios, axios);
Vue.use(Vuex);

const snackbar = {
  namespaced: true,
  state: () => ({
    content: "",
    color: "",
  }),
  mutations: {
    showMessage(state, payload) {
      state.content = payload.content;
      state.color = payload.color;
    },
  },
};

export default new Vuex.Store({
  state: {
    routeObject: {},
    Useremail: "",
    auth: false,
    user_details: {},
    OrgObject: {},
    getIssueDetails: {},
    getPreviousRoute: "",

  },
  mutations: {
    SET_CURRENT_ROUTE(state, routeObject) {
      state.routeObject = routeObject;
    },
    SET_USEREMAIL(state, email) {
      state.Useremail = email;
    },
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    set_user_details(state, user_details) {
      state.user_details = user_details;
    },
    set_latest_org(state, OrgObject) {
      state.OrgObject = OrgObject;
    },
    SET_ISSUE_DETAILS(state, getIssueDetails) {
      state.getIssueDetails = getIssueDetails;
    },
    SET_PREVIOUS_ROUTE(state, route) {
      state.getPreviousRoute = route;
    },
  },
  getters: {
    get_issue_details: (state) => {
      return state.getIssueDetails;
    },
    get_previous_route: (state) => {
      return state.getPreviousRoute;
    },
    get_current_route: (state) => {
      return state.routeObject;
    },
    get_useremail: (state) => {
      return state.Useremail;
    },
    get_auth: (state) => {
      return state.auth || false;
    },
    get_user_details: (state) => {
      return state.user_details;
    },
    get_latest_org: (state) => {
      return state.OrgObject;
    },
  },
  actions: {},
  modules: {
    snackbar,
  },
  plugins: [createPersistedState()],
});