import Vue from "vue";
import VueRouter from "vue-router";
// import Login from "../views/Login.vue";
import forgotPassword from "../views/forgotPassword.vue";
import ReportIssue from "@/components/ReportIssue.vue"
import NewLogin from "@/views/NewLogin.vue"

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Login",
  //   component: Login,
  // },
  {
    path: "/",
    name: "NewLogin",
    component: NewLogin,

  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: forgotPassword,
  },
  {
    path: "/ReportIssue",
    name: "ReportIssue",
    component: ReportIssue,
  },
  {
    path: "/Home",
    name: "Home",
    component: () => import(/* webpackChunkName: "Main" */ "../views/Home.vue"),
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../components/Cards/Dashboard/Dashboard.vue"
          ),
      },
      {
        path: "/Onboarded",
        name: "Onboarded",
        component: () =>
          import(
            /* webpackChunkName: "Onboarded" */ "../components/Cards/Organisations/Onboarded.vue"
          ),
      },
      {
        path: "/Prospect",
        name: "Prospect",
        component: () =>
          import(
            /* webpackChunkName: "Prospect" */ "../components/Cards/Organisations/Prospect.vue"
          ),
      },


      {
        path: "/logoutDialogView",
        name: "logoutDialogView",
        component: () =>
          import(
            /* webpackChunkName: "Prospect" */ "../components/Dialogs/logoutDialogView.vue"
          ),
      },








      {
        path: "/Activated",
        name: "Activated",
        component: () =>
          import(
            /* webpackChunkName: "Activated" */ "../components/Cards/Organisations/Activated.vue"
          ),
      },
      {
        path: "/LocationOverall",
        name: "LocationOverall",
        component: () =>
          import(
            /* webpackChunkName: "LocationOverall" */ "../components/Cards/Locations/LocationOverall.vue"
          ),
      },
      {
        path: "/SalesRep",
        name: "SalesRep",
        component: () =>
          import(
            /* webpackChunkName: "SalesRep" */ "../components/Cards/SalesRepresentative/SalesRep.vue"
          ),
      },
      {
        path: "/releaseView",
        name: "releaseView",
        component: () =>
          import(
            /* webpackChunkName: "releaseView" */ "../components/Cards/SalesRepresentative/releaseView.vue"
          ),
      },
      {
        path: "/Customer",
        name: "Customer",
        component: () =>
          import(
            /* webpackChunkName: "Customer" */ "../components/Cards/Organisations/Customer.vue"
          ),
      },
      {
        path: "/DeActived",
        name: "DeActived",
        component: () =>
          import(
            /* webpackChunkName: "Customer" */ "../components/Cards/Organisations/DeActived.vue"
          ),
      },
      {
        path: "/RecentView",
        name: "RecentView",
        component: () =>
          import(
            /* webpackChunkName: "Customer" */ "../components/Cards/Organisations/RecentView.vue"
          ),
      },
      {
        path: "/Trial",
        name: "Trial",
        component: () =>
          import(
            /* webpackChunkName: "Customer" */ "../components/Cards/Organisations/Trial.vue"
          ),
      },
      {
        path: "/MyPrezence",
        name: "MyPrezence",
        component: () =>
          import(
            /* webpackChunkName: "MyPrezence" */ "../components/Cards/AppUsers/MyPrezence.vue"
          ),
      },
      {
        path: "/Prezence",
        name: "Prezence",
        component: () =>
          import(
            /* webpackChunkName: "Prezence" */ "../components/Cards/AppUsers/Prezence.vue"
          ),
      },
      {
        path: "/Credits",
        name: "Credits",
        component: () =>
          import(
            /* webpackChunkName: "Credits" */ "../components/Cards/Credits/Credits.vue"
          ),
      },
      {
        path: "/Produced",
        name: "Produced",
        component: () =>
          import(
            /* webpackChunkName: "Produced" */ "../components/Cards/QRs/Produced.vue"
          ),
      },
      {
        path: "/Printed",
        name: "Printed",
        component: () =>
          import(
            /* webpackChunkName: "Printed" */ "../components/Cards/QRs/Printed.vue"
          ),
      },
      {
        path: "/Claimed",
        name: "Claimed",
        component: () =>
          import(
            /* webpackChunkName: "Printed" */ "../components/Cards/QRs/Claimed.vue"
          ),
      },
      {
        path: "/Administrator",
        name: "Administrator",
        component: () =>
          import(
            /* webpackChunkName: "Administrator" */ "../components/Cards/Admins/Administrator.vue"
          ),
      },
      {
        path: "/AuditActions",
        name: "AuditActions",
        component: () =>
          import(
            /* webpackChunkName: "Audit Actions" */ "../components/Cards/AuditLogs/AuditActions.vue"
          ),
      },
      {
        path: "/ResolvedSwipes",
        name: "ResolvedSwipes",
        component: () =>
          import(
            /* webpackChunkName: "Resolved Swipes" */ "../components/Cards/AuditLogs/ResolvedSwipes.vue"
          ),
      },
      {
        path: "/PromoTransactions",
        name: "PromoTransactions",
        component: () =>
          import(
            /* webpackChunkName: "Transactions" */ "../components/Cards/Transactions/PromoTransactions.vue"
          ),
      },
      {
        path: "/TransactionReport",
        name: "TransactionReport",
        component: () =>
          import(
            /* webpackChunkName: "Transactions" */ "../components/Cards/Transactions/TransactionReport.vue"
          ),
      },

      {
        path: "/RazorTransactions",
        name: "RazorTransactions",
        component: () =>
          import(
            /* webpackChunkName: "Transactions" */ "../components/Cards/Transactions/RazorTransactions.vue"
          ),
      },
      {
        path: "/BmsPromoTransactions",
        name: "BmsPromoTransactions",
        component: () =>
          import(
            /* webpackChunkName: "Transactions" */ "../components/Cards/Transactions/BmsPromoTransactions.vue"
          ),
      },
      {
        path: "/BmsPaidTransactions",
        name: "BmsPaidTransactions",
        component: () =>
          import(
            /* webpackChunkName: "Transactions" */ "../components/Cards/Transactions/BmsPaidTransactions.vue"
          ),
      },
      {
        path: "/IosPaidTransactions",
        name: "IosPaidTransactions",
        component: () =>
          import(
            /* webpackChunkName: "Transactions" */ "../components/Cards/Transactions/IosPaidTransactions.vue"
          ),
      },
      {
        path: "/Referrals",
        name: "Referrals",
        component: () =>
          import(
            /* webpackChunkName: "Referrals" */ "../components/Cards/Referrals/Referrals.vue"
          ),
      },
      {
        path: "/PublishRelease",
        name: "PublishRelease",
        component: () =>
          import(
            /* webpackChunkName: "releaseView" */ "../components/Cards/SalesRepresentative/PublishRelease.vue"
          ),
      },
      {
        path: "/CabinetList",
        name: "CabinetList",
        component: () =>
          import(
            /* webpackChunkName: "releaseView" */ "../components/Cards/CabinetList.vue"
          ),
      },
      {
        path: "/FormLibrary",
        name: "FormLibrary",
        component: () =>
          import(
            /* webpackChunkName: "releaseView" */ "../components/Forms/FormLibrary.vue"
          ),
      },

      {
        path: "/CreadSettings",
        name: "CreadSettings",
        component: () =>
          import(
            /* webpackChunkName: "releaseView" */ "../components/Cards/CreadSettings.vue"
          ),
      },
      {
        path: "/BannerList",
        name: "BannerList",
        component: () =>
          import(
            /* webpackChunkName: "releaseView" */ "../components/Cards/BannerList.vue"
          ),
      },
      {
        path: "/ScanCredit",
        name: "ScanCredit",
        component: () =>
          import(
            /* webpackChunkName: "releaseView" */ "../components/Cards/ScanCredit.vue"
          ),
      },
      {
        path: "/DeletedMember",
        name: "DeletedMember",
        component: () =>
          import(
            /* webpackChunkName: "releaseView" */ "../components/Cards/DeletedMember.vue"
          ),
      },

      



    ],
  },
  {
    path: "/ReportIssue",
    name: "ReportIssue",
    component: () => import("@/views/ReportIssue.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
