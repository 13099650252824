import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Amplify from "aws-amplify";
import * as VueGoogleMaps from "vue2-google-maps";
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBGx0GVYXax5i6Bs3eCglSrUGaIAvKrgXk",
    libraries: "places" // necessary for places input
  }
});
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_OChbmWVSE",
    region: "ap-south-1",
    userPoolWebClientId: "7673q81g1h13ttld9dclucoht4",
  },
  API: {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://lxslt3cma5fktmf35zntfjfj2m.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
  },
  endpoints: [
    {
      name: "Report_issue",
      endpoint: " https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
    },]
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')